import { createContext, useContext } from 'react';
import { Environment } from 'src/types/environment';

export interface EnvironmentContextState {
    environment: Environment;
    setEnvironment: (newEnvironment: Environment) => void;
}

export function createEnvironmentContextState(
    {
        environment = {
            environmentType: 'local',
            apiUrlBase: '',
            googleRecaptchaPublicKey: ''
        }
    }: Partial<Pick<EnvironmentContextState, 'environment'>> = {}
): EnvironmentContextState {
    const environmentContextState: EnvironmentContextState = {
        environment,
        setEnvironment(newEnvironment: Environment): void {
            environmentContextState.environment = newEnvironment;
        }
    };

    return environmentContextState;
}

const EnvironmentContext = createContext<EnvironmentContextState>(createEnvironmentContextState());

export function useEnvironment(): Environment {
    return useContext(EnvironmentContext).environment;
}

export default EnvironmentContext;
