import { createContext, useContext } from 'react';

export interface NavigationPaneContextState {
    isNavigationPaneOpen: boolean;
    toggleNavigationPane: () => void;
    isMobileMenuOpen: boolean;
    toggleMobileMenu: () => void;
    closeMobileMenu: () => void;
    activeKeys: string[];
    toggleActiveKey: (activeKey: string) => void;
}

const navigationPaneContextState: NavigationPaneContextState = {
    // isNavigationPaneOpen: false, // DISABLE TABLEAU EMBED
    isNavigationPaneOpen: true,
    toggleNavigationPane(): void { },
    isMobileMenuOpen: false,
    toggleMobileMenu(): void { },
    closeMobileMenu(): void { },
    activeKeys: [],
    toggleActiveKey(activeKey: string): void { }
};

export function createNavigationPaneContextState(
    isNavigationPaneOpen: boolean,
    activeKeys: string[]
): NavigationPaneContextState {
    navigationPaneContextState.isNavigationPaneOpen = isNavigationPaneOpen;
    navigationPaneContextState.activeKeys = activeKeys;

    return navigationPaneContextState;
}

const NavigationPaneContext = createContext<NavigationPaneContextState>(
    createNavigationPaneContextState(
        navigationPaneContextState.isNavigationPaneOpen,
        navigationPaneContextState.activeKeys
    )
);

export function useNavigationPaneContext(): NavigationPaneContextState {
    return useContext(NavigationPaneContext);
}

export default NavigationPaneContext;
