import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";
import { useEffect, useRef, useCallback } from "react";
import 'src/components/EmbeddedTableau/EmbeddedTableau.css';
import { isMobile } from 'react-device-detect';
const { tableau }: any = window;

interface EmbeddedTableauProps extends FunctionComponentProps {
    dashboardUrl: string;
}

function EmbeddedTableau({ dashboardUrl }: EmbeddedTableauProps): FunctionComponentReturnType {
    const tableauRef = useRef(null);



    let handleResize = useCallback(()=>{
        if(!isMobile) {
            var ref = document.getElementsByTagName('iframe')[0];
            if(ref != null)
                var scaler = ref.parentElement.offsetWidth/ref.offsetWidth;
            if (scaler < 1) {
                ref.style['-ms-zoom'] =  scaler + ""
                ref.style['-moz-transform']= 'scale(' + scaler + ')'
                ref.style['-moz-transform-origin']= '0 0'
                ref.style['-o-transform']= 'scale(' + scaler + ')'
                ref.style['-o-transform-origin']= '0 0'
                ref.style['-webkit-transform']= 'scale(' + scaler + ')'
                ref.style['-webkit-transform-origin']= '0 0'
            }
        }
    },[isMobile])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        };
    },[handleResize]);
    function initViz() {
        let url = dashboardUrl;
        if(!url.startsWith("http")) {
            url = window.location.origin + url;
        }
        new tableau.Viz(tableauRef.current, url);
        function checkForIframeAndResizeWhenExists() {
            if(!(document.getElementsByTagName('iframe').length > 0 && document.getElementsByTagName('iframe')[0].offsetWidth > 2)) {
                window.setTimeout(checkForIframeAndResizeWhenExists, 500);
            } else {
                handleResize();
            }
        }
        checkForIframeAndResizeWhenExists();
    }

    useEffect(() => initViz(), []);

    return (
        <div
            className="embedded-dashboard-tableau"
            ref={tableauRef}
        />
    )
}

export default EmbeddedTableau;
