import { Dialog } from "@c1/gravity-react";
import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";
import './FormModal.css';

interface FormModalProps extends FunctionComponentProps {
    title: String;
    active: Boolean;
    formId: String;
    okButtonType?: 'action' | 'progressive' | 'destructive' | 'text' | 'compact' | 'ghost';
    okButtonDisabled?: Boolean;
    size?: 'normal' | 'large' | 'xlarge';
    onCancel?: () => void;
}

/**
 * This wrapper component allows for prop overrides to the underlying
 * c1-gravity Dialog component
 * Reference here for additional props:
 * https://github-pages.cloud.capitalone.com/Gravity/gravity/docs/gravity-react/catalog/#/dialog
 */
function FormModal({
        title,
        active,
        formId,
        okButtonType,
        okButtonDisabled,
        onCancel,
        children,
        ...props
    }: FormModalProps
    & {[prop: string]: any}
): FunctionComponentReturnType {
    return (
        <Dialog
            active={active}
            title={title}
            okText={title}
            cancelText="Cancel"
            theme="dark"
            okButtonForm={formId}
            okButtonHtmlButtonType="submit"
            okButtonType={okButtonType}
            okButtonDisabled={okButtonDisabled}
            onCancel={onCancel}
            { ...props }
        >
            { children }
        </Dialog>
    )
}

export default FormModal;
