import { Button, Table } from "@c1/gravity-react";
import { useState } from "react";
import ActionTray from "src/containers/ActionTray/ActionTray";
import { Dashboard } from "src/types/dashboard";
import { Column } from "src/types/gravity";
import { AdminTableDataSource, TableFiltersType } from "src/types/shared";
import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";
import { createFilterableColumn, filterItem } from "src/utils/tableUtils";
import DeleteButton from "../buttons/DeleteButton/DeleteButton";
import { ColumnTitleAndDataIndex } from "../tables/AdminTable/AdminTable";
import 'src/components/DashboardsAdminTable/DashboardsAdminTable.css';

export interface DashboardsAdminTableProps extends FunctionComponentProps {
    dashboards: Dashboard[];
    onClickDelete: (dashboard: Dashboard) => void;
    onClickEdit: (dashboard: Dashboard) => void;
}

function DashboardsAdminTable({
        dashboards,
        onClickDelete,
        onClickEdit,
    }: DashboardsAdminTableProps
): FunctionComponentReturnType {
    const [filters, setFilters] = useState<TableFiltersType<Dashboard>>({});

    const columns: ColumnTitleAndDataIndex<Dashboard>[] = [
        { title: 'Partner', dataIndex: 'partner' },
        { title: 'Name', dataIndex: 'dashboardName' },
        { title: 'Description', dataIndex: 'dashboardDescription' },
        { title: 'Type', dataIndex: 'dashboardType' },
        //{ title: 'URL', dataIndex: 'dashboardUrl' }
    ];

    function createColumns(): Column[] {
        const output: Column[] =  columns.map((x: ColumnTitleAndDataIndex<Dashboard>): Column =>
            createFilterableColumn(x.title, x.dataIndex, filters, setFilters));
        output.push({ title: '', dataIndex: 'actions', key: 'actions' });
        return output;
    }

    function createDataSource(): AdminTableDataSource<Dashboard>[] {
        return dashboards
            .filter(d => filterItem(overrideItemToFilter(d), filters))
            .map((d: Dashboard): AdminTableDataSource<Dashboard> => ({
                ...d,
                ...overrideTableDataSourceMappedFields(d),
                key: getKey(d),
                actions: (
                    <ActionTray>
                        <Button
                            className="grv-margin--tiny"
                            type="progressive"
                            id={'copp-button-edit-' + getKey(d)}
                            compact
                            onClick={() => onClickEdit(d)}
                        >
                            Edit
                        </Button>
                        <DeleteButton
                            className="grv-margin--tiny"
                            onClick={() => onClickDelete(d)}
                        />
                    </ActionTray>
                )
            }));
    }

    function getKey(item: Dashboard): string {
        return `dashboards-admin-table-row-${item.dashboardId}`;
    }

    function overrideItemToFilter(item: Dashboard): Omit<Dashboard, 'partner'> & { partner: string } {
        return {
            ...item,
            partner: `${item.partner.name} (${item.partner.type})`
        };
    }

    function overrideTableDataSourceMappedFields(item: Dashboard): { partner: string } {
        return {
            partner: `${item.partner.name} (${item.partner.type})`
        }
    }

    return (
        <>
            <Table
                className="dashboard-admin-table"
                columns={createColumns()}
                dataSource={createDataSource()}
            />
        </>
    )
}

export default DashboardsAdminTable;
